import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { MapPin, Calendar, Clock, Coffee } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { createMeetz } from '../services/api';
import { UserContext } from '../context/UserContext'; // Assume you have a UserContext

function CreateMeet() {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [meetData, setMeetData] = useState({
    title: '',
    place: '',
    startDateTime: null,
    endDateTime: null,
    description: '',
  });
  const [tagInput, setTagInput] = useState('');

  const handleChange = (e) => {
    setMeetData({ ...meetData, [e.target.name]: e.target.value });
  };


  const addSuggestedDeetz = () => {
    if (!meetData.tags.includes('deetz')) {
      setMeetData({
        ...meetData,
        tags: [...meetData.tags, 'deetz'],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      console.error('User is not logged in');
      return;
    }
    const newMeet = {
      ...meetData,
      hostId: user.id,
      attendees: [user.id],
      pendingAttendees: []
    };

    try {
      await createMeetz(newMeet);
      // Set a flag in localStorage to indicate a new meet was created
      localStorage.setItem('newMeetCreated', 'true');
      // Dispatch a custom event to notify other components
      window.dispatchEvent(new Event('newMeetCreated'));
      history.push('/my-meetz');
    } catch (error) {
      console.error('Error creating meet:', error);
    }
  };

  const handleDateChange = (date) => {
    setMeetData({ ...meetData, dateTime: date });
  };


  return (
    <div className="h-screen flex flex-col items-center justify-center p-4">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
        <h1 className="text-2xl font-bold text-center text-[#2A8C7F] dark:text-[#5CD2C1] mb-4">create a meetz</h1>
        <form onSubmit={handleSubmit} className="space-y-3">
          <input
            type="text"
            name="title"
            value={meetData.title}
            onChange={handleChange}
            placeholder="what's your meetz about?"
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />

          <div className="relative">
            <Coffee className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#5CD2C1]" size={16} />
            <input
              type="text"
              name="place"
              value={meetData.place}
              onChange={handleChange}
              placeholder="enter meeting place"
              className="w-full pl-8 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-2"
            />
          </div>

          <div className="space-y-2">
            <div className="relative">
              <Calendar className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#5CD2C1]" size={16} />
              <DatePicker
                selected={meetData.startDateTime}
                onChange={(date) => {
                  const endDate = new Date(date.getTime() + 30 * 60000);
                  setMeetData({ ...meetData, startDateTime: date, endDateTime: endDate });
                }}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                className="w-full pl-8 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                placeholderText="Select start date and time"
              />
            </div>
            <div className="relative">
              <Clock className="absolute left-2 top-1/2 transform -translate-y-1/2 text-[#5CD2C1]" size={16} />
              <DatePicker
                selected={meetData.endDateTime}
                onChange={(date) => setMeetData({ ...meetData, endDateTime: date })}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                className="w-full pl-8 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                placeholderText="Select end date and time"
                minDate={meetData.startDateTime}
                minTime={new Date(meetData.startDateTime?.getTime() || 0)}
                maxTime={new Date(new Date().setHours(23, 59, 59, 999))}
                filterTime={(time) => {
                  const selectedDate = new Date(time);
                  const startDateTime = new Date(meetData.startDateTime);
                  return selectedDate.getTime() > startDateTime.getTime();
                }}
              />
            </div>
          </div>

          <textarea
            name="description"
            value={meetData.description}
            onChange={handleChange}
            placeholder="deetz (optional)"
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5CD2C1] bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            rows="4"
          ></textarea>

          <button
            type="submit"
            className="w-full bg-[#5CD2C1] text-white py-2 px-4 rounded-lg hover:bg-[#4CB3A3] transition duration-300"
          >
            create meetz
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreateMeet;
