import React, { useState, useEffect, useContext } from 'react';
import { Calendar } from 'lucide-react';
import MeetCard from '../components/MeetCard';
import Modal from '../components/Modal';
import MeetDetails from './MeetDetails';
import { fetchHostedMeetz, fetchJoinedMeetz } from '../services/api';
import { UserContext } from '../context/UserContext';
import { format } from 'date-fns';

function MyMeetz() {
  const [hostedMeetz, setHostedMeetz] = useState([]);
  const [joinedMeetz, setJoinedMeetz] = useState([]);
  const [activeTab, setActiveTab] = useState('hosted');
  const [selectedMeet, setSelectedMeet] = useState(null);
  const [hasNewMeet, setHasNewMeet] = useState(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      refreshMeetz();
    }
  }, [user]);

  useEffect(() => {
    localStorage.removeItem('newMeetCreated');
    setHasNewMeet(false);
  }, []);

  const refreshMeetz = async () => {
    try {
      if (user) {
        const hostedMeetzData = await fetchHostedMeetz(user.id);
        const joinedMeetzData = await fetchJoinedMeetz(user.id);
        setHostedMeetz(hostedMeetzData);
        setJoinedMeetz(joinedMeetzData);
      } else {
        setHostedMeetz([]);
        setJoinedMeetz([]);
      }
    } catch (error) {
      console.error('Error fetching meetz:', error);
    }
  };

  const handleCloseMeetDetails = () => {
    setSelectedMeet(null);
    refreshMeetz();
  };

  const renderMeetzList = (meetz) => (
    <div className="space-y-4">
      {meetz.length === 0 ? (
        <p>No meetz found.</p>
      ) : (
        meetz.map((meetData) => {
          if (!meetData) return null; // Skip rendering if meetData is undefined
          const meet = activeTab === 'joined' ? meetData.meetz : meetData;
          if (!meet || !meet.id) return null; // Skip rendering if meet or meet.id is undefined
          const isPending = activeTab === 'joined' ? meetData.pending : false;
          return (
            <MeetCard
              key={meet.id}
              meet={meet}
              onClick={() => setSelectedMeet(meetData)}
              disableSwipe={true}
              hideRemoveButton={true}
              isPending={isPending}
            />
          );
        })
      )}
    </div>
  );

  return (
    <div className="pt-1 pb-16 px-2 max-w-md mx-auto">
      <h1 className="text-2xl font-bold text-[#2A8C7F] mb-4 flex items-center justify-center">
        my meetz <Calendar className="ml-2" size={20} />
      </h1>
      <div className="flex mb-3">
        <button
          className={`flex-1 py-1 px-2 text-center text-sm ${activeTab === 'hosted' ? 'bg-[#5CD2C1] text-white' : 'bg-gray-200 text-gray-700'} rounded-l-lg`}
          onClick={() => setActiveTab('hosted')}
        >
          Hosted Meetz
        </button>
        <button
          className={`flex-1 py-1 px-2 text-center text-sm ${activeTab === 'joined' ? 'bg-[#5CD2C1] text-white' : 'bg-gray-200 text-gray-700'} rounded-r-lg`}
          onClick={() => setActiveTab('joined')}
        >
          Joined Meetz
        </button>
      </div>
      {activeTab === 'hosted' ? renderMeetzList(hostedMeetz) : renderMeetzList(joinedMeetz)}
      {selectedMeet && (
        <Modal isOpen={!!selectedMeet} onClose={handleCloseMeetDetails}>
          <MeetDetails 
            meet={activeTab === 'joined' ? selectedMeet.meetz : selectedMeet}
            onClose={handleCloseMeetDetails} 
            isHosted={activeTab === 'hosted'}
            isPending={activeTab === 'joined' ? selectedMeet.pending : false}
          />
        </Modal>
      )}
    </div>
  );
}

export default MyMeetz;
