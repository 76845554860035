import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { signInWithGoogle, createUserProfile } from '../services/api';

function Login() {
  const { setUser } = useContext(AppContext);
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleGoogleSignIn = async () => {
    try {
      const { user, exists } = await signInWithGoogle();
      if (exists) {
        setUser(user);
        history.push('/discover');
      } else if (user) {
        setError('Account doesn\'t exist. Please use the register button.');
      } else {
        setError('Failed to sign in. Please try again.');
      }
    } catch (error) {
      console.error('Google sign-in failed:', error);
      setError('Failed to sign in. Please try again.');
    }
  };

  const handleGoogleRegister = async () => {
    try {
      const { user, exists } = await signInWithGoogle();
      if (exists) {
        setError('Account already exists. Please sign in.');
      } else if (user) {
        await createUserProfile(user);
        setUser(user);
        history.push('/discover');
      } else {
        setError('Failed to register. Please try again.');
      }
    } catch (error) {
      console.error('Google register failed:', error);
      setError('Failed to register. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="space-y-4">
          <button
            onClick={handleGoogleSignIn}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sign in with Google
          </button>
          <button
            onClick={handleGoogleRegister}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            Register with Google
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
