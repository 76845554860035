import axios from 'axios';
import { mockMeetz, mockUser } from './mockdata/meetz';
import { auth } from '../firebase/config';
import { signInWithPopup, GoogleAuthProvider, signOut } from 'firebase/auth';

//const API_BASE_URL = 'https://api.meetz.site';
//const API_BASE_URL = 'http://localhost:8080/api.meetz.site/';
export const API_BASE_URL = 'http://localhost:8080/api';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const googleProvider = new GoogleAuthProvider();


export const fetchMeetz = async () => {
  // If you have a working API, uncomment the following lines:
  // const response = await fetch('your-api-endpoint');
  // return response.json();

  // For now, return mock data
  return new Promise((resolve) => {
    setTimeout(() => resolve(mockMeetz), 500); // Simulate API delay
  });
};

export const createMeetz = async (meetData) => {
  await delay(500);
  const newMeet = {
    ...meetData,
    id: mockMeetz.length + 1,
    host: mockUser,
    attendees: [mockUser]
  };
  mockMeetz.push(newMeet);
  return newMeet;
};

export const joinMeetz = async (meetId, userId) => {
  await delay(500);
  const meet = mockMeetz.find(m => m.id === meetId);
  if (meet) {
    meet.attendees.push(mockUser);
  }
  return meet;
};

export const flakeMeetz = async (meetId, userId) => {
  await delay(500);
  const meet = mockMeetz.find(m => m.id === meetId);
  if (meet) {
    meet.attendees = meet.attendees.filter(a => a.id !== userId);
  }
  return meet;
};

export const updateMeetz = async (meetId, meetData) => {
  await delay(500);
  const index = mockMeetz.findIndex(m => m.id === meetId);
  if (index !== -1) {
    mockMeetz[index] = { ...mockMeetz[index], ...meetData };
  }
  return mockMeetz[index];
};

export const acceptAttendee = async (meetId, attendeeId) => {
  const response = await axios.post(`${API_BASE_URL}/meetz/${meetId}/accept-attendee`, { attendeeId });
  return response.data;
};

export const declineAttendee = async (meetId, attendeeId) => {
  const response = await axios.post(`${API_BASE_URL}/meetz/${meetId}/decline-attendee`, { attendeeId });
  return response.data;
};

export const fetchProfile = async (userId) => {
  try {
    return null;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};

export const updateProfile = async (userId, profileData) => {
  try {
    //const response = await profileApi.updateProfile(userId, profileData);
    return null;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

export const fetchHostedMeetz = async (userId) => {
  await delay(500);
  return mockMeetz.filter(meet => meet.host.id === userId);
};

export const fetchJoinedMeetz = async (userId) => {
  await delay(500);
  return mockMeetz.filter(meet => meet.attendees.some(a => a.id === userId));
};

export const fetchDiscoverMeetz = async () => {
  await delay(500);
  return mockMeetz;
};

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = {
      id: result.user.uid,
      name: result.user.displayName,
      email: result.user.email,
      profilePicture: result.user.photoURL,
    };
    
    try {
      const response = await axios.post(`${API_BASE_URL}/users/check`, user.email);
      const exists = response.data;
      if (!exists) {
        await registerUser(user);
      }
      return { user, exists };
    } catch (error) {
      console.error("Error checking user existence or registering:", error);
      throw error;
    }
  } catch (error) {
    console.error("Error signing in with Google", error);
    throw error;
  }
};

export const logOut = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Error signing out", error);
    throw error;
  }
};

export const registerUser = async (userData) => {
  const userRegistrationDTO = {
    firebaseUid: userData.id,
    email: userData.email,
    name: userData.name,
    profilePicture: userData.profilePicture
  };

  try {
    const response = await axios.post(`${API_BASE_URL}/users/register`, userRegistrationDTO);
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

export const deleteUserData = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      await user.delete();
      await axios.delete(`${API_BASE_URL}/profiles/${user.uid}`);
    } else {
      throw new Error("No user is currently signed in");
    }
  } catch (error) {
    console.error("Error deleting user account", error);
    throw error;
  }
};

export const createUserProfile = async (user) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/profiles`, user);
    return response.data;
  } catch (error) {
    console.error("Error creating user profile", error);
    throw error;
  }
};

// Add more API calls as needed





