export const mockMeetz = [
  {
    id: 1,
    title: 'Coffee Meetup',
    tags: ['coffee', 'networking'],
    place: 'Starbucks on Main St',
    dateTime: new Date('2023-06-15T14:00:00'),
    description: 'Let\'s meet for coffee and chat!',
    host: {
      id: 1,
      name: 'John Doe',
      profilePicture: 'https://example.com/john.jpg'
    },
    attendees: [
      { id: 2, name: 'Jane Smith' },
      { id: 3, name: 'Bob Johnson' }
    ]
  },
  // Add more mock meetz here
];

export const mockUser = {
  id: 1,
  name: 'John Doe',
  profilePicture: 'https://example.com/john.jpg'
};

