import React, { useContext, useState, useEffect } from 'react';
import AppContext from '../context/AppContext';
import { Sun, Moon, Bell, Calendar, User, Hourglass } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Header() {
  const { darkMode, toggleDarkMode } = useContext(AppContext);
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const [hasNewMeet, setHasNewMeet] = useState(false);

  useEffect(() => {
    const checkNewMeet = () => {
      const newMeetCreated = localStorage.getItem('newMeetCreated');
      if (newMeetCreated === 'true') {
        setHasNewMeet(true);
      }
    };

    checkNewMeet();

    const handleNewMeetCreated = () => {
      setHasNewMeet(true);
    };

    window.addEventListener('newMeetCreated', handleNewMeetCreated);

    return () => {
      window.removeEventListener('newMeetCreated', handleNewMeetCreated);
    };
  }, []);

  const handleMyMeetzClick = () => {
    setHasNewMeet(false);
    localStorage.removeItem('newMeetCreated');
  };

  return (
    <header className="bg-[#5CD2C1] dark:bg-[#2A8C7F] text-white fixed top-0 left-0 right-0 z-10 p-4 h-16">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className={`w-10 h-10 ${darkMode ? 'bg-gray-700' : 'bg-white'} rounded-lg flex items-center justify-center`}>
            <span className={`${darkMode ? 'text-[#4CB3A3]' : 'text-[#5CD2C1]'} font-bold text-lg`}>m<sub className="text-xs">z</sub></span>
          </div>          <span className="text-2xl font-bold">meetz</span>
        </div>
        <div className="flex items-center space-x-4">
          {!isLoginPage && (
            <>
              <Link to="/my-meetz" onClick={handleMyMeetzClick} className="relative">
                <Calendar className="text-white" size={24} />
                {hasNewMeet && (
                  <span className="absolute -top-1 -right-1 bg-red-500 rounded-full w-3 h-3"></span>
                )}
              </Link>
              <Link to="/pending"><Hourglass size={24} /></Link>
              <Link to="/notifications"><Bell size={24} /></Link>
              <Link to="/profile"><User size={24} /></Link>
            </>
          )}
          <button 
            onClick={toggleDarkMode}
            className="w-10 h-6 rounded-full bg-gray-300 flex items-center transition duration-300 focus:outline-none shadow"
          >
            <div
              className={`w-6 h-6 rounded-full transform transition duration-300 ${
                darkMode ? 'translate-x-full bg-[#2A8C7F]' : 'translate-x-0 bg-white'
              }`}
            >
              {darkMode ? (
                <Moon size={16} className="m-auto text-white" />
              ) : (
                <Sun size={16} className="m-auto text-gray-800" />
              )}
            </div>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
