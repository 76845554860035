import React from 'react';
import { useContext } from 'react';
import AppContext from '../context/AppContext';
import { deleteUserData } from '../services/api';

function DataDeletionInstructions() {
  const { user, setUser } = useContext(AppContext);

  const handleDeleteAccount = async () => {
    if (user) {
      try {
        await deleteUserData(user.uid);
        setUser(null);
        // Redirect to home page or show a confirmation message
      } catch (error) {
        console.error("Error deleting account", error);
        // Show error message to user
      }
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Data Deletion Instructions</h1>
      <p className="mb-4">To delete your account and all associated data, please follow these steps:</p>
      <ol className="list-decimal list-inside mb-4">
        <li>Log in to your account</li>
        <li>Go to your account settings</li>
        <li>Click on the "Delete Account" button at the bottom of the page</li>
        <li>Confirm your decision to delete your account</li>
      </ol>
      <p className="mb-4">Alternatively, you can click the button below to delete your account immediately:</p>
      <button 
        onClick={handleDeleteAccount}
        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
      >
        Delete My Account
      </button>
    </div>
  );
}

export default DataDeletionInstructions;
